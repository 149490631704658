/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorEnum,
    ColorEnumFromJSON,
    ColorEnumFromJSONTyped,
    ColorEnumToJSON,
} from './ColorEnum';
import {
    CurrencyDto,
    CurrencyDtoFromJSON,
    CurrencyDtoFromJSONTyped,
    CurrencyDtoToJSON,
} from './CurrencyDto';
import {
    IconNameEnum,
    IconNameEnumFromJSON,
    IconNameEnumFromJSONTyped,
    IconNameEnumToJSON,
} from './IconNameEnum';
import {
    PortfolioTypeEnum,
    PortfolioTypeEnumFromJSON,
    PortfolioTypeEnumFromJSONTyped,
    PortfolioTypeEnumToJSON,
} from './PortfolioTypeEnum';

/**
 * 
 * @export
 * @interface PortfolioDto
 */
export interface PortfolioDto {
    /**
     * 
     * @type {IconNameEnum}
     * @memberof PortfolioDto
     */
    iconName: IconNameEnum;
    /**
     * 
     * @type {ColorEnum}
     * @memberof PortfolioDto
     */
    colorName: ColorEnum;
    /**
     * 
     * @type {PortfolioTypeEnum}
     * @memberof PortfolioDto
     */
    type: PortfolioTypeEnum;
    /**
     * 
     * @type {CurrencyDto}
     * @memberof PortfolioDto
     */
    currency: CurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    portfolioValue: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    realizedPortfolioValue: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    currencyImpactUnrealizedValue: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    currencyImpactRealizedValue: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    invested: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    realizedInvested: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    unrealizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    unrealizedProfitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    todayUnrealizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    todayUnrealizedProfitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    realizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    realizedProfitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    numberOfTrades: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    numberOfAssets: number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioDto
     */
    isRecalculating: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioDto
     */
    processed: boolean;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    dividends: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    dividendsNextYearPrediction: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    dividendsYield: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioDto
     */
    dividendsYieldOnCost: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioDto
     */
    selected: boolean;
    /**
     * 
     * @type {string}
     * @memberof PortfolioDto
     */
    portfolioHistoryCurrencyCode: string;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioDto
     */
    lastUpdated: Date;
}

export function PortfolioDtoFromJSON(json: any): PortfolioDto {
    return PortfolioDtoFromJSONTyped(json, false);
}

export function PortfolioDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iconName': IconNameEnumFromJSON(json['iconName']),
        'colorName': ColorEnumFromJSON(json['colorName']),
        'type': PortfolioTypeEnumFromJSON(json['type']),
        'currency': CurrencyDtoFromJSON(json['currency']),
        'portfolioValue': json['portfolioValue'],
        'realizedPortfolioValue': json['realizedPortfolioValue'],
        'currencyImpactUnrealizedValue': json['currencyImpactUnrealizedValue'],
        'currencyImpactRealizedValue': json['currencyImpactRealizedValue'],
        'invested': json['invested'],
        'realizedInvested': json['realizedInvested'],
        'unrealizedProfit': json['unrealizedProfit'],
        'unrealizedProfitPercentage': json['unrealizedProfitPercentage'],
        'todayUnrealizedProfit': json['todayUnrealizedProfit'],
        'todayUnrealizedProfitPercentage': json['todayUnrealizedProfitPercentage'],
        'realizedProfit': json['realizedProfit'],
        'realizedProfitPercentage': json['realizedProfitPercentage'],
        'numberOfTrades': json['numberOfTrades'],
        'numberOfAssets': json['numberOfAssets'],
        'isRecalculating': json['isRecalculating'],
        'processed': json['processed'],
        'dividends': json['dividends'],
        'dividendsNextYearPrediction': json['dividendsNextYearPrediction'],
        'dividendsYield': json['dividendsYield'],
        'dividendsYieldOnCost': json['dividendsYieldOnCost'],
        'id': json['id'],
        'name': json['name'],
        'selected': json['selected'],
        'portfolioHistoryCurrencyCode': json['portfolioHistoryCurrencyCode'],
        'lastUpdated': (new Date(json['lastUpdated'])),
    };
}

export function PortfolioDtoToJSON(value?: PortfolioDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iconName': IconNameEnumToJSON(value.iconName),
        'colorName': ColorEnumToJSON(value.colorName),
        'type': PortfolioTypeEnumToJSON(value.type),
        'currency': CurrencyDtoToJSON(value.currency),
        'portfolioValue': value.portfolioValue,
        'realizedPortfolioValue': value.realizedPortfolioValue,
        'currencyImpactUnrealizedValue': value.currencyImpactUnrealizedValue,
        'currencyImpactRealizedValue': value.currencyImpactRealizedValue,
        'invested': value.invested,
        'realizedInvested': value.realizedInvested,
        'unrealizedProfit': value.unrealizedProfit,
        'unrealizedProfitPercentage': value.unrealizedProfitPercentage,
        'todayUnrealizedProfit': value.todayUnrealizedProfit,
        'todayUnrealizedProfitPercentage': value.todayUnrealizedProfitPercentage,
        'realizedProfit': value.realizedProfit,
        'realizedProfitPercentage': value.realizedProfitPercentage,
        'numberOfTrades': value.numberOfTrades,
        'numberOfAssets': value.numberOfAssets,
        'isRecalculating': value.isRecalculating,
        'processed': value.processed,
        'dividends': value.dividends,
        'dividendsNextYearPrediction': value.dividendsNextYearPrediction,
        'dividendsYield': value.dividendsYield,
        'dividendsYieldOnCost': value.dividendsYieldOnCost,
        'id': value.id,
        'name': value.name,
        'selected': value.selected,
        'portfolioHistoryCurrencyCode': value.portfolioHistoryCurrencyCode,
        'lastUpdated': (value.lastUpdated.toISOString()),
    };
}

